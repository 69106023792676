import React from 'react'
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    LegacyCard,
    EmptyState,
    LegacyStack,
  } from "@shopify/polaris"
function PostPurchaseInstruction(props) {
    return (
        <Page backAction={{
                content: 'Offers',
                onAction: () => {
                    navigate("/app/offers/createOfferType")
                }}} 
                title="Create New Offer">
        <Layout>
            <Layout.Section>
                <LegacyCard>
                <LegacyCard.Section>
                <LegacyStack distribution="fill">
                    <EmptyState
                        heading="Let’s activate UpsellPlus Post Purchase Offers on your store "
                        action={{
                            content: 'I did it! Let’s configure an offer',
                            onAction: () => navigate('/app/offers/createPostPurchase', {replace: true})
                        }}
                        image="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/create-offer.svg?alt=media&token=b18f4972-21bc-4eb7-8a06-4040e86f9d47"
                        fullWidth={true}
                    >
                    <div style={{width: '50%', margin: 'auto', textAlign:'left'}}>
                        <p>
                            1. On your Shopify store, go to Settings
                        </p>
                        <p>
                            2. Click ‘Checkout’
                        </p>
                        <p>
                            3. Scroll down to ‘Post Purchase page’
                        </p>
                        <p>
                            4. Choose ‘UpsellPlus’
                        </p>
                    </div>
                    </EmptyState>
                    <div style={{width: '60%', margin: 'auto', marginTop: '-6rem', fontSize: '10px'}}>
                        <span>Note: Post-purchase offers add secondary payments to customers' orders. When using the manual payment capture method you may need to capture them separately.</span>
                    </div>
                </LegacyStack>
                </LegacyCard.Section>
                </LegacyCard>
            </Layout.Section>
        </Layout>
    </Page>
    );
}

export default PostPurchaseInstruction;